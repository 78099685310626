import { getRule } from '@/utils/rules';
const transactionalSchema = [

  {
    label: 'Lessee',
    value: 'lessee',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lessor',
    value: 'lessor',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lease Hold',
    value: 'leaseHold',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rental Rate',
    value: 'rentalRate',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Monthly Rent',
    value: 'monthlyRent',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rent Per SQFT',
    value: 'rentPerSqft',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Term',
    value: 'term',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },




  {
    label: 'Buyer',
    value: 'buyer',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Seller',
    value: 'seller',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Recording Date',
    value: 'recordingDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Doc Number',
    value: 'docNumber',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Marketing Time',
    value: 'marketingTime',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Broker',
    value: 'broker',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Confirm Date',
    value: 'confirmDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Occupancy',
    value: 'occupancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale'],
  },
  {
    label: 'Rights',
    value: 'rights',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/SF',
    value: 'priceSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Unit',
    value: 'priceUnit',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/Unit',
    saleTypes: ['Sale'],
  },
  {
    label: 'Sale Price',
    value: 'priceSale',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price Adj',
    value: 'priceAdj',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Land Value',
    value: 'landValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'House Value',
    value: 'houseValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Dairy Improvements',
    value: 'dairyImprovements',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Acre',
    value: 'priceAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Milking Cow',
    value: 'priceMilkingCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Milking Cow',
    saleTypes: ['Sale'],
  },
  {
    label: 'Improvements/Cow',
    value: 'improvementsCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Cow',
    saleTypes: ['Sale'],
  },
];
export default transactionalSchema;
