import { getRule, addCommas } from '@/utils/rules';

const SQUARE_FT_PER_ACRE = 43560
const siteInfoSchema = [
  {
    label: 'Site Area Acres',
    value: 'siteAreaAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    calc: async (value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaSqft', addCommas(value * SQUARE_FT_PER_ACRE), id, record, true)
    },
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Site Area SF',
    value: 'siteAreaSqft',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'SF',
    calc: async(value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaAcres', addCommas(value / SQUARE_FT_PER_ACRE), id, record, true)
    },
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Total Acres',
    value: 'totalAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    saleTypes: ['Sale','Lease'],
    calc: async(value, id, record, saveRecord, dataRecord) => {
      const totalAcres = value;
      let { landValuePerAcre } = dataRecord.siteInfo;
      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      }
      if(!landValuePerAcre) {
        landValuePerAcre = 0;
      }
      landValuePerAcre = addCommas(priceSale / totalAcres);
      console.log('calced land value per acre');
      console.log(landValuePerAcre);
      await saveRecord('siteInfo', 'landValuePerAcre', landValuePerAcre, id, record, true)
    },
  },
  {
    label: 'GSA',
    value: 'GSA',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Planted Acres',
    value: 'plantedAcres',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pumping Restrictions',
    value: 'pumpingRestrictions',
    type: 'checkbox',
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Land Value Per Acre',
    value: 'landValuePerAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale','Lease'],
  },

  {
    label: 'Site Coverage Ratio',
    value: 'siteCoverageRatio',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Surplus Land',
    value: 'surplusLand',
    type: 'checkbox',
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Site Allocation',
    value: 'siteAllocation',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Off Sites',
    value: 'offSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'On Sites',
    value: 'onSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Shape',
    value: 'shape',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Topography',
    value: 'topography',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },


  {
    label: 'Dairy Site',
    value: 'dairySite',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Percent Site Used',
    value: 'percentSiteUsed',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Farm Land',
    value: 'farmLand',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Milking',
    value: 'actualMilking',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Cow/Dairy Site Acre',
    value: 'cowDairySiteAc',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: 'Cows/DSA',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Soils',
    value: 'soils',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Ground Water',
    value: 'groundWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Surface Water',
    value: 'surfaceWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Permit',
    value: 'permit',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Use',
    value: 'use',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Irrigation System',
    value: 'irrigationSystem',
    type: 'text',
    subCategories: [
      'Permanent Plantings',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Utilities',
    value: 'utilities',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Zoning',
    value: 'zoning',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Orientation',
    value: 'orientation',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
];

export default siteInfoSchema
