import { getRule, removeCommas } from '@/utils/rules';

const financialSchema = [
  {
    label: 'PGI',
    value: 'PGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { vacancy } = dataRecord.financial;
      if(!vacancy) {
        vacancy = 0;
      }
      let { expenditures } = dataRecord.financial;
      if(!expenditures) {
        expenditures = 0;
      }
      let { priceSale } = dataRecord.siteInfo;
      if(!priceSale) {
        priceSale = 0;
      }
      const EGI = removeCommas(value) * (1 - removeCommas(vacancy)/100);
      console.log('check me');
      console.log(vacancy);
      console.log(1 - removeCommas(vacancy)/100)
      console.log(`${removeCommas(value)} * ${(1 - removeCommas(vacancy)/100)}`);
      console.log(`EGI ${EGI}`);
      const NOI = parseFloat(removeCommas(EGI)) + parseFloat(removeCommas(expenditures));
      await saveRecord('financial', 'EGI' , EGI, id, record, true)
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = NOI / priceSale;
      const OER = expenditures / EGI * -1;
      console.log('');
      console.log('*********');
      console.log(`calced OAR to ${OAR}`);
      console.log(`calced OER to ${OER}`);
      console.log('*********');
      console.log('');

      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'Vacancy',
    value: 'vacancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { PGI } = dataRecord.financial;
      if(!PGI) {
        PGI = 0;
      }
      let { expenditures } = dataRecord.financial;
      if(!expenditures) {
        expenditures = 0;
      }
      let { priceSale } = dataRecord.siteInfo;
      if(!priceSale) {
        priceSale = 0;
      }
      const EGI = removeCommas(PGI) * (1 - removeCommas(value)/100);
      const NOI = parseFloat(removeCommas(EGI)) + parseFloat(removeCommas(expenditures));
      await saveRecord('financial', 'EGI' , EGI, id, record, true)
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = NOI / priceSale;
      const OER = expenditures / EGI * -1
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }

  },
  {
    label: 'EGI',
    value: 'EGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { PGI, expenditures, vacancy } = dataRecord.financial;
      if(!PGI) {
        PGI = 0;
      }
      if(!expenditures) {
        expenditures = 0;
      }
      if(!vacancy) {
        vacancy = 0;
      }
      let { priceSale } = dataRecord.siteInfo;
      if(!priceSale) {
        priceSale = 0;
      }
      const EGI = parseFloat(removeCommas(value));
      const NOI = parseFloat(EGI) + parseFloat(removeCommas(expenditures));
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = NOI / priceSale;
      const OER = expenditures / EGI * -1
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'Expenditures',
    value: 'expenditure',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { EGI, expenditures } = dataRecord.financial;
      if(!EGI) {
        EGI = 0;
      }
      console.log('parsed value');
      console.log(parseFloat(value));
      console.log(EGI);
      let { priceSale } = dataRecord.siteInfo;
      if(!priceSale) {
        priceSale = 0;
      }
      if(!expenditures) {
        expenditures = 0;
      }

      const NOI = parseFloat(removeCommas(EGI)) + parseFloat(removeCommas(value));
      console.log(NOI);
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = NOI / priceSale;
      const OER = expenditures / EGI * -1
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'NOI', 
    value: 'NOI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let { EGI } = dataRecord.financial;
      if(!EGI) {
        EGI = 0;
      }
      let { priceSale } = dataRecord.siteInfo;
      if(!priceSale) {
        priceSale = 0;
      }
      const expenditure = parseFloat(removeCommas(value)) - parseFloat(removeCommas(EGI));
      await saveRecord('financial', 'expenditure', expenditure, id, record, true);
      const OAR = value / priceSale;
      const OER = expenditure / EGI * -1
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'OAR',
    value: 'OAR',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '%',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'OER',
    value: 'OER',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Source',
    value: 'source',
    type: 'switch',
    indicators: {
			0: 'Estimated',
			1: 'Actual',
		},
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
		label: 'Lease Type',
    value: 'leaseType',
    type: 'select',
    items: [
			'NNN',
			'Gross',
			'Modified Gross',
			'Full Service',
		],
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
	}		
];
export default financialSchema;
