import { getRule } from '@/utils/rules';
const leaseInformationSchema = [
  {
    label: 'Tenant',
    value: 'tenant',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Suite Size',
    value: 'suiteSize',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: 'SF',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Term',
    value: 'term',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: 'months',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Start Date',
    value: 'startDate',
    type: 'datepicker',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Options',
    value: 'options',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Lease Type',
    value: 'leaseType',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Rent/Month',
    value: 'rentMonth',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/mo',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Rent/SF',
    value: 'rentSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Increases',
    value: 'increases',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },

];
export default leaseInformationSchema;
