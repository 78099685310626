import { getRule } from '@/utils/rules';
const apartmentFinancialSchema = [
  {
    label: 'Actual Source',
    value: 'aSource',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma Source',
    value: 'pSource',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Unit Income',
    value: 'aUnitIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Pro Forma Unit Income',
    value: 'pUnitIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Other Income',
    value: 'aOtherIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Pro Forma Other Income',
    value: 'pOtherIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual PGI',
    value: 'aPGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Pro Forma PGI',
    value: 'pPGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Loss To Lease',
    value: 'aLossToLease',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Pro Forma Loss To Lease',
    value: 'pLossToLease',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual VC',
    value: 'aVC',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Pro Forma VC',
    value: 'pVC',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    }
  },
  {
    label: 'Actual EGI',
    value: 'aEGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      const { apartmentFinancial } = dataRecord;
      if(apartmentFinancial) {
        const itemRecord = apartmentFinancial;
        if(itemRecord) {
          const newValue = itemRecord.aAOtherIncome + itemRecord.APgi + itemRecord.ALossToLease + itemRecord.aVc;
          itemRecord.aEgi = newValue;
          await saveRecord('itemRecord0', 'aEgi', itemRecord.aEgi, record, true)
        }
      }
    },

  },
  {
    label: 'Pro Forma EGI',
    value: 'pEGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Expenses',
    value: 'aExpenses',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma Expenses',
    value: 'pExpenses',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual NOI',
    value: 'aNoi',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma NOI',
    value: 'pNoi',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual EGI Ratio',
    value: 'aEGIRatio',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma EGI Ratio',
    value: 'pEGIRatio',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual CAP Rate',
    value: 'aCAPRate',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma CAP Rate',
    value: 'pCAPRate',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
];
export default apartmentFinancialSchema;
